<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-text-field
          v-model="vm.firstname"
          label="First name"
          v-validate="'required'"
          data-vv-as="firstname"
          name="firstname"
          :error-messages="errors.collect('firstname')"
          :disabled="locked"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="vm.lastname"
          label="Last name"
          v-validate="'required'"
          data-vv-as="lastname"
          name="lastname"
          :error-messages="errors.collect('lastname')"
          :disabled="locked"
        ></v-text-field>
      </v-col>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="vm.dateOfBirth"
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="vm.dateOfBirth"
            label="Date Of Birth"
            readonly
            v-on="on"
            v-validate="'required'"
            data-vv-as="dateOfBirth"
            name="dateOfBirth"
            :error-messages="errors.collect('dateOfBirth')"
            :disabled="locked"
          ></v-text-field>
        </template>
        <v-date-picker v-model="vm.dateOfBirth" no-title scrollable color="green lighten-1" dark>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(vm.dateOfBirth)">OK</v-btn>
        </v-date-picker>
      </v-menu>
      <v-col cols="12"></v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.gender"
          :items="gender"
          label="Gender"
          data-vv-as="gender"
          name="gender"
          v-validate="'required'"
          :error-messages="errors.collect('gender')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <address-picker
          :detail-mode="false"
          label="Address"
          :min-characters="4"
          v-model="vm.address"
          required
          vvalidate="'required'"
          data-vv-as="Address"
          name="address"
          :error-messages="errors.collect('address')"
        ></address-picker>
      </v-col>
      <v-col cols="12">
        <v-radio-group v-model="vm.isPostalTheSame" label="Is your Postal Address the same?">
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" v-if="vm.isPostalTheSame == false">
        <address-picker
          :detail-mode="false"
          label="Postal address"
          :min-characters="4"
          v-model="vm.postalAddress"
          :required="true"
          v-validate="'required'"
          data-vv-as="postalAddress"
          name="postalAddress"
          :error-messages="errors.collect('postalAddress')"
        ></address-picker>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.homeNumber"
          label="Home phone number"
          name="homeNumber"
          type="number"
          :error-messages="errors.collect('homeNumber')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.mobileNumber"
          label="Mobile number"
          name="mobileNumber"
          type="number"
          :error-messages="errors.collect('mobileNumber')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.email"
          label="E-mail address"
          v-validate="'email'"
          :required="true"
          data-vv-as="email"
          name="email"
          :error-messages="errors.collect('email')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.medicareNumber"
          label="Medicare Number"
          v-validate="'required'"
          data-vv-as="medicareNumber"
          name="medicareNumber"
          :error-messages="errors.collect('medicareNumber')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.cRNNumber"
          label="CRN number"
          v-validate="'required'"
          data-vv-as="cRNNumber"
          name="cRNNumber"
          :error-messages="errors.collect('cRNNumber')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.nDISNumber"
          label="NDIS number"
          v-validate="'required'"
          :rules="[rules.counter, rules.ndis]"
          data-vv-as="nDISNumber"
          :maxlength="9"
          name="nDISNumber"
          type="email"
          
          :error-messages="errors.collect('nDISNumber')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.sWSNumber"
          label="Supported Wage System number"
          v-validate="'required'"
          data-vv-as="sWSNumber"
          name="sWSNumber"
          :error-messages="errors.collect('sWSNumber')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.taxFileNumber"
          label="Tax file number"
          v-validate="'required'"
          data-vv-as="taxFileNumber"
          name="taxFileNumber"
          :error-messages="errors.collect('taxFileNumber')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.maritalStatus"
          :items="maritalStatuses"
          label="Marital Status"
          v-validate="'required'"
          data-vv-as="maritalStatuses"
          name="maritalStatuses"
          :error-messages="errors.collect('maritalStatuses')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.primaryDisability"
          :items="disabilities"
          label="Primary disability"
          v-validate="'required'"
          data-vv-as="disabilities"
          name="disabilities"
          item-text="title"
          item-value="value"
          :error-messages="errors.collect('disabilities')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.secondaryDisability"
          :items="disabilities"
          label="Secondary disability"
          v-validate
          item-text="title"
          item-value="value"
          data-vv-as="secondaryDisability"
          name="secondaryDisability"
          :error-messages="errors.collect('secondaryDisability')"
        ></v-select>
      </v-col>
      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
var Models = require("./models.js");
import Vue from "vue";
import VeeValidate from "vee-validate";
import AddressPicker from "../../../components/core/AddressPicker";
Vue.use(VeeValidate);
//import router from "vue-router";
export default {

name: "participant-details",

  $_veeValidate: {
    validator: "new"
  },
  
  components: {
    AddressPicker
  },
  data: () => ({
    locked: false,
    e1: 0,
    max: 9,
    rules: {
        
        counter:  value => value.length==9  || 'Number should be 9 digits long and no sybmols or characters', 
        ndis:  value => {
            const pattern =  /^[1-9][0-9]*$/
            return pattern.test(value) || 'Incorrect NDIS format only digits are allowed'
          }},
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    vm: {},
    form: {},
    valid: true,
    maritalStatuses: ["Single", "Married", "Defacto", "Separated", "Widowed"],
    gender: ["he/him/his", "she/her", "they/them", "Prefer not to Disclose"],
    sex: ["Male", "Female", "Prefer not to Disclose"],
    disabilities: [
      { title: "None", value: null },
      { title: "Acquired Brain Injury", value: "Acquired Brain Injury" },
      { title: "Autism (Inc. Asperger’s)", value: "Autism (Inc. Asperger’s)" },
      { title: "Deaf Blind", value: "Deaf Blind" },
      { title: "Hearing Impairment", value: "Hearing Impairment" },
      { title: "Intellectual", value: "Intellectual" },
      { title: "Neurological", value: "Neurological" },
      { title: "Physical", value: "Physical" },
      { title: "Psychiatric", value: "Psychiatric" },
      { title: "Sensory Unknown", value: "Sensory Unknown" },
      { title: "Specific Learning", value: "Specific Learning" },
      { title: "Speech", value: "Speech" },
      { title: "Visually Impaired", value: "Visually Impaired" },
      { title: "Other", value: "Other" }
    ],

    hairColour: [
      "Brown",
      "Light Brown",
      "Dark Brown",
      "Black",
      "Blonde",
      "Grey"
    ],
    eyeColour: ["Hazel", "Blue", "Brown", "Green"],
    indigenousStatus: [
      "None",
      "Aboriginal",
      "Torres Strait Islander",
      "South Sea Islander Origin",
      "Does not want to give this information"
    ],
    residencyStatus: [
      "Australian Citizen",
      "Permanent Resident",
      "Temporary Resident"
    ],
    communicationMethod: [
      "Sign Language",
      "Spoken Language (effectively)",
      "Little or No Communication",
      "Other non-spoken Communication",
      "Not Known"
    ],
    interpreterServices: ["Not Applicable", "Sometimes", "Always Needed"],
    mainIncomeSource: [
      "Not Known",
      "Disability Support Pension",
      "Newstart / Youth Allowance",
      "Paid Employment",
      "Compensation Income",
      "TAC",
      "Other Income",
      "Other Pension / Benefit",
      "Nil Income"
    ],

    employment: [
      "Not Employed",
      "Job Seeker",
      "Employed - Part Time",
      "Employed - Casual",
      "Employed - Full Time",
      "Not Applicable (due to age)",
      "Work Experience",
      "Supported Employment - Full Time",
      "Supported Employment - Part Time",
      "Volunteering"
    ],

    recentWorkParticipation: [
      "Paid Work",
      "Unpaid Work",
      "Not in the Workforce",
      "Not working but looking for Work",
      "Community Development Employment"
    ],

    checkbox: false
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },

  methods: {
    icon() {
      return this.locked ? "mdi-lock-open" : "mdi-lock";
    },
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.participantDetails == undefined
          ? new Models.ParticipantDetails()
          : this.$store.getters.currentCase.participantDetails;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addParticipantDetails() {

      this.currentCase.participantDetails = this.vm;
      this.$store.dispatch("setCurrentCase", this.currentCase);
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.addParticipantDetails();
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    beforeRouteLeave (to, from, next) {
        const reply = window.confirm('You have unsaved changes! Do you want to leave?')

          if (!reply) {
            // deny navigation
            next(false);
          } else {
            // proceed with navigation
            next(true);
          }
        },

   
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

