<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-radio-group
          v-model="vm.isLikelyToWanderOff"
          label="Is the participant likely to wander off / abscond?"
          v-validate="'required'"
          data-vv-as="participant likely to wander off / abscond"
          name="isLikelyToWanderOff"
          :error-messages="errors.collect('isLikelyToWanderOff')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isAbleToRemainSeatedWhilstTraveling"
          label="Is the participant likely to remain seated whilst traveling?"
          v-validate="'required'"
          data-vv-as="participant likely to remain seated whilst traveling"
          name="isAbleToRemainSeatedWhilstTraveling"
          :error-messages="errors.collect('isAbleToRemainSeatedWhilstTraveling')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isLikelyToRemoveSeatBelt"
          label="Is participant likely to remove their seatbelt whilst traveling?"
          v-validate="'required'"
          data-vv-as="participant likely to remove their seatbelt whilst traveling"
          name="isLikelyToRemoveSeatBelt"
          :error-messages="errors.collect('isLikelyToRemoveSeatBelt')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.hasActivitiesRequirementWhilstTraveling"
          label="Does the participant require activities whilst traveling (e.g books, music etc)?"
          v-validate="'required'"
          data-vv-as="Does the participant require activities whilst traveling "
          name="hasActivitiesRequirementWhilstTraveling"
          :error-messages="errors.collect('hasActivitiesRequirementWhilstTraveling')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" v-if="vm.hasActivitiesRequirementWhilstTraveling == true">
        <v-textarea
          v-model="vm.listOfTravelActivities"
          label="Please list the required activities"
          v-validate="'required'"
          data-vv-as="Please list them"
          name="listOfTravelActivities"
          :error-messages="errors.collect('listOfTravelActivities')"
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.recommenedMaxTravelTime"
          label="Recommended maximum travel time"
          v-validate="'required'"
          data-vv-as="Recommended maximum travel time"
          name="recommenedMaxTravelTime"
          :error-messages="errors.collect('recommenedMaxTravelTime')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.hasWarningSignsOfBehaviourDifficulties"
          label="Does the participant show warning signs of potential behaviour difficulties whilst on outings?"
          v-validate="'required'"
          data-vv-as="Participant's warning signs of potential behaviour difficulties whilst on outings"
          name="hasWarningSignsOfBehaviourDifficulties"
          :error-messages="errors.collect('hasWarningSignsOfBehaviourDifficulties')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" v-if="vm.hasWarningSignsOfBehaviourDifficulties == true">
        <v-text-field
          v-model="vm.listWarningSigns"
          label="If Yes, please list the warning signs?"
          v-validate="'required'"
          data-vv-as="Warning signs"
          name="listWarningSigns"
          :error-messages="errors.collect('listWarningSigns')"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-radio-group
          v-model="vm.hasGotPBSP"
          label="Does the participant have a Positive Behaviour Support Plan?"
          v-validate="'required'"
          data-vv-as="Participant have a positive behaviour support plan"
          name="hasGotPBSP"
          :error-messages="errors.collect('hasGotPBSP')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "behaviour-form",

  data: () => ({
    vm: {},
    form: {}
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.behaviorForm == undefined
          ? {}
          : this.$store.getters.currentCase.behaviorForm;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.behaviorForm = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

