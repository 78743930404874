<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-text-field
          v-model="vm.weight"
          label="Weight (in kgs)"
          v-validate="'required|min:2'"
          data-vv-as="Weight"
          name="weight"
          :error-messages="errors.collect('weight')"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="vm.height"
          label="Height (in cm)"
          v-validate="'required|min:2'"
          data-vv-as="height"
          name="height"
          :error-messages="errors.collect('height')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.hairColour"
          :items="hairColour"
          label="Hair colour"
          v-validate="'required'"
          data-vv-as="hairColour"
          name="hairColour"
          :error-messages="errors.collect('hairColour')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.eyeColour"
          :items="eyeColour"
          label="Eye colour"
          v-validate="'required'"
          data-vv-as="eyeColour"
          name="eyeColour"
          :error-messages="errors.collect('eyeColour')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model="vm.distinguishingFeatures"
          label="Distinguishing features"
          filled
          rows="1"
          v-validate="'required'"
          data-vv-as="distinguishingFeatures"
          name="distinguishingFeatures"
          :error-messages="errors.collect('distinguishingFeatures')"
        ></v-textarea>
      </v-col>
      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "features-form",
  model: {},
  data: () => ({
    vm: {},
    form: {},
    hairColour: [
      "Brown",
      "Light Brown",
      "Dark Brown",
      "Black",
      "Blonde",
      "Grey"
    ],
    eyeColour: ["Hazel", "Blue", "Brown", "Green"]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.features == undefined
          ? {}
          : this.$store.getters.currentCase.features;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.features = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

