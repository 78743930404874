<template>
  <v-container>
    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on">Add contact</v-btn>
      </template>
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>Medical Contact</v-card-title>

          <v-card-text>
            <v-col cols="12">
              <v-radio-group
                v-model="vm.contactType"
                label="Contact Type"
                v-validate="'required'"
                data-vv-as="Contact type"
                name="contactType"
                :error-messages="errors.collect('contactType')"
              >
                <v-radio label="Doctor" value="Doctor"></v-radio>
                <v-radio label="Specialist" value="Specialist"></v-radio>
                <v-radio label="Allied Health" value="Allied Health"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.firstName"
                label="First name"
                v-validate="'required'"
                data-vv-as="First name"
                name="firstName"
                :error-messages="errors.collect('firstName')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.familyName"
                label="Family name"
                v-validate="'required'"
                data-vv-as="Family name"
                name="familyName"
                :error-messages="errors.collect('familyName')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.email"
                label="Email"
                v-validate="'required|email'"
                data-vv-as="Email"
                name="email"
                :error-messages="errors.collect('email')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.workPhone"
                label="Work Phone"
                v-validate="'required'"
                data-vv-as="Work phone"
                name="workPhone"
                :error-messages="errors.collect('workPhone')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.mobilePhone"
                label="Mobile Phone"
                v-validate="'required'"
                data-vv-as="Mobile phone"
                name="mobilePhone"
                :error-messages="errors.collect('mobilePhone')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <address-picker
                :detail-mode="false"
                label="Practice Address"
                :min-characters="4"
                v-model="vm.practiceAddressLine1"
                :required="true"
                v-validate="'required'"
                data-vv-as="practiceAddressLine1"
                name="practiceAddressLine1"
                :error-messages="errors.collect('practiceAddressLine1')"
              ></address-picker>
            </v-col>

            <v-col cols="12">
              <v-radio-group
                v-model="vm.isPostalAddressthesame"
                label="Is the Postal Address same"
                v-validate="'required'"
                data-vv-as="Is postal address the same"
                name="isPostalAddressthesame"
                :error-messages="errors.collect('isPostalAddressthesame')"
              >
                <v-radio label="Yes" v-bind:value="true"></v-radio>
                <v-radio label="No" v-bind:value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" v-if="vm.isPostalAddressthesame == false">
              <address-picker
                :detail-mode="false"
                label="Postal Address "
                :min-characters="4"
                v-model="vm.postalAddress"
                :required="true"
                v-validate="'required'"
                data-vv-as="postalAddress"
                name="postalAddress"
                :error-messages="errors.collect('postalAddress')"
              ></address-picker>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close">Cancel</v-btn>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-data-table
          id="list-table"
          ref="datatable"
          :headers="headers"
          :items="contacts"
          :loading="loading"
          mobile-breakpoint="700"
          item-key="index"
          :dense="true"
        >
          <template slot="item.actions" slot-scope="props">
            <div>
              <v-icon slot="activator" small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>

              <v-icon slot="activator" small @click="deleteItem(props.item)">mdi-delete</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-btn color="primary" @click="next">Continue</v-btn>
  </v-container>
</template>



<script>
var Models = require("./models.js");
import Vue from "vue";
import VeeValidate from "vee-validate";
import AddressPicker from "../../../components/core/AddressPicker";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  components: {
    AddressPicker
  },
  name: "medical-contacts-form",
  data: () => ({
    vm: new Models.MedicalContacts(),
    contacts: [],
    form: {},
    dialog: false,
    valid: true,
    totalItems: 0,
    editIndex: -1,
    loading: false,
    queryFilters: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "Contact Type", value: "contactType" },
      { text: "firstName", value: "firstName" },
      { text: "familyName", value: "familyName" },
      { text: "email", value: "email" },
      { text: "mobilePhone", value: "mobilePhone" },
      { text: "workPhone", value: "workPhone" },
      { text: "", value: "actions" }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm = {};
      this.contacts =
        this.currentCase.medicalContacts === undefined
          ? []
          : this.currentCase.medicalContacts;
    },
    editItem(item) {
      this.editedIndex = this.contacts.indexOf(item);
      this.vm = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.contacts.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.contacts.splice(index, 1);
        this.currentCase.medicalContacts = this.contacts;
        this.$store.dispatch("setCurrentCase", this.currentCase);
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;
      }, 300);
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.editedIndex > -1) {
            Object.assign(this.contacts[this.editedIndex], this.vm);
          } else {
            this.contacts.push(this.vm);
          }
          this.currentCase.medicalContacts = this.contacts;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.vm = {};
          this.editedIndex = -1;
          this.close();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    next() {
      this.$emit("next-step");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

