<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-textarea
          filled
          v-model="vm.activitiesEnjoyedByClient"
          label="Please list activities enjoyed by participant"
          v-validate="'required'"
          rows="1"
          data-vv-as="Activities enjoyed by participant"
          name="activitiesEnjoyedByClient"
          :error-messages="errors.collect('activitiesEnjoyedByClient')"
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-textarea
          filled
          rows="1"
          v-model="vm.activitiesNotEnjoyedByClient"
          label="Please list activities not enjoyed by participant"
          v-validate="'required'"
          data-vv-as="Activities not enjoyed by participant"
          name="activitiesNotEnjoyedByClient"
          :error-messages="errors.collect('activitiesNotEnjoyedByClient')"
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-textarea
          filled
          rows="1"
          v-model="vm.activitiesClientCannotDoDueToMedicalReasons"
          label="Please list activities participant can not do due to medical or other reasons"
          v-validate="'required'"
          data-vv-as="Activities participant cannot do due to medical reasons"
          name="activitiesClientCannotDoDueToMedicalReasons"
          :error-messages="errors.collect('activitiesClientCannotDoDueToMedicalReasons')"
        ></v-textarea>
      </v-col>

      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "community-access-form",
  model: {},
  data: () => ({
    vm: {},
    residentialSettings: [
      "Family Home",
      "Self Contained Unit",
      "Shared Accomodation",
      "Private Residence"
    ],
    form: {}
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.communityAccess == undefined
          ? {}
          : this.$store.getters.currentCase.communityAccess;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.communityAccess = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

