<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-select
          v-model="vm.livingArrangements"
          :items="livingArrangements"
          label="Living Arrangements"
          v-validate="'required'"
          data-vv-as="Living arrangements"
          name="livingArrangements"
          :error-messages="errors.collect('livingArrangements')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.otherOccupants"
          :items="otherOccupants"
          label="Other Occupants"
          v-validate="'required'"
          data-vv-as="Other occupants"
          name="otherOccupants"
          :error-messages="errors.collect('otherOccupants')"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isSpecialAccommodation"
          label="Is the participant currently living in a Refuge,Emergency or Support Accomodation, Hostel,Boarding House, Hotel, Short Stay in Caravan Park, Staying with friends or have nowhere to stay?"
          v-validate="'required'"
          data-vv-as="Special accomodation"
          name="isSpecialAccommodation"
          :error-messages="errors.collect('isSpecialAccommodation')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "living-arrangements-form",
  model: {},
  data: () => ({
    vm: {},
    form: {},
    livingArrangements: [
      "Aged Care Provider",
      "Family Home",

      "Private Residence",
      "Self Contained Unit",
      "Shared Accomodation",
      "Specialist Disability Accommodation",
      "Supported independent Living",
      "Other"
    ],
    otherOccupants: [
      "Lives Alone",
      "Lives with Family",
      "Lives with Others",
      "Group Home",
      "Prefer not to say",
      "Not Known"
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.livingArrangments == undefined
          ? {}
          : this.$store.getters.currentCase.livingArrangments;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.livingArrangments = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

