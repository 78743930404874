<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-radio-group
          v-model="vm.hasCriminalConvictionJuvenileWithin5Years"
          label="Has the participant been convicted of a criminal offence as a juvenile in the last 5 years?"
          v-validate="'required'"
          data-vv-as="Criminal conviction - Juvenile"
          name="hasCriminalConvictionJuvenileWithin5Years"
          :error-messages="errors.collect('hasCriminalConvictionJuvenileWithin5Years')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
          <v-radio label="Unknown" value="Unknown"></v-radio> 
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <v-radio-group
          v-model="vm.hasCriminalConvictionAdultWithin10Years"
          label="Has the participant been convicted of a criminal offence as an adult in the last 10 years?"
          v-validate="'required'"
          data-vv-as="Criminal conviction - Adult"
          name="hasCriminalConvictionAdultWithin10Years"
          :error-messages="errors.collect('hasCriminalConvictionAdultWithin10Years')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
          <v-radio label="Unknown" value="Unknown"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col
        cols="12"
        v-if="vm.hasCriminalConvictionAdultWithin10Years  == true || vm.hasCriminalConvictionJuvenileWithin5Years  == true"
      >
        <v-radio-group
          v-model="vm.hascustodialSentenceMoreThan1month"
          label="Did the participant  serve a custodial sentence of more than one month?"
          v-validate="'required'"
          data-vv-as="Sentence more than 1 month"
          name="hascustodialSentenceMoreThan1month"
          :error-messages="errors.collect('hascustodialSentenceMoreThan1month')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "police-record-form",
  model: {},
  data: () => ({
    vm: {},
    form: {}
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.policeRecord == undefined
          ? {}
          : this.$store.getters.currentCase.policeRecord;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.policeRecord = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

