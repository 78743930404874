<template>
  <div style="display:flex" class="participant-form">
    <div style="margin-right:10px;">
      <v-timeline dense>
        <v-timeline-item
          right
          small
          v-bind:step="item.step"
          v-for="(item, i) in timeline"
          v-bind:key="i"
          v-bind:class="{ 'activetimeline-item': (selectedStep == item || item.children.indexOf(selectedStep)>=0) }"
        >
          <v-card class="timeline-card elevation-2 hvr-rectangle-out">
            <v-card-title
              class="subtitle-1 timeline-text"
              v-bind:class="{ 'selected': selectedStep == item }"
              @click="setSelectedStep(item,item)"
            >{{item.name}}</v-card-title>
            <v-card-text v-if="item.children.length>0">
              <ul v-for="(child, y) in item.children" v-bind:key="y">
                <li
                  @click="setSelectedStep(item,child)"
                  class="timeline-text"
                  v-bind:class="{ 'selected': selectedStep == child }"
                >{{child.name}}</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </div>
    <div style="margin-right:20px;    width: 100%;">
      <v-card class="elevation-2" v-if="selectedStep!=null">
        <v-card-title class="subheading">{{selectedStep.name}}</v-card-title>
        <v-card-text>
          <component v-bind:is="selectedStep.component" v-on:next-step="next()"></component>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>


<script>
import participantDetailsForm from "./participant-details-form";
import supportNeedsForm from "./support-needs-form";
import contactList from "./contact-list";
import employmentTrainingEducationForm from "./employment-training-education-form";
import bankingForm from "./banking-form";
import signaturesForm from "./signatures-form";
import consentForm from "./consent-form";
import livingArrangementsForm from "./living-arrangements-form";
import featuresForm from "./features-form";
import cultureForm from "./culture-form";
import communicationForm from "./communication-form";
import policeRecordForm from "./police-record-form";
import communityAccessForm from "./community-access-form";
import safetyAndOutings from "./safety-and-outings-form";
import behaviorForm from "./behavior-form";
import medicalContactsForm from "./medical-contacts-form";
import medicalForm from "./medical-form.vue";
import medicationForm from "./medication-form";

export default {
  name: "mainForm",
  components: {
    "participant-details-form": participantDetailsForm,
    "support-needs-form": supportNeedsForm,
    "medical-form": medicalForm,
    "contact-list": contactList,
    "consent-form": consentForm,
    "living-arrangements-form": livingArrangementsForm,
    "features-form": featuresForm,
    "employment-training-education-form": employmentTrainingEducationForm,
    "banking-form": bankingForm,
    "signatures-form": signaturesForm,
    "culture-form": cultureForm,
    "communication-form": communicationForm,
    "police-record-form": policeRecordForm,
    "community-access-form": communityAccessForm,
    "saftey-and-outings-form": safetyAndOutings,
    "behaviour-form": behaviorForm,
    "medical-contacts-form": medicalContactsForm,
    "medication-form": medicationForm
  },
  data: () => ({
    selectedStep: null,
    parentStep: null,
    timeline: [
      {
        name: "Participant details",
        step: 1,
        component: "participant-details-form",
        children: [
          {
            name: "Participant details",
            step: 1.1,
            component: "participant-details-form"
          },
          {
            name: "Consent",
            step: 1.2,
            component: "consent-form"
          },
          {
            name: "Living arrangements",
            step: 1.3,
            component: "living-arrangements-form"
          },
          {
            name: "Features",
            step: 1.4,
            component: "features-form"
          },
          {
            name: "Culture",
            step: 1.5,
            component: "culture-form"
          },
          {
            name: "Communication",
            step: 1.6,
            component: "communication-form"
          },
          {
            name: "Police record",
            step: 1.8,
            component: "police-record-form"
          }
        ]
      },
      {
        name: "Support needs",
        step: 2,
        component: "support-needs-form",
        children: [
          {
            name: "Support needs",
            step: 2.2,
            component: "support-needs-form"
          },
          {
            name: "Community access",
            step: 2.3,
            component: "community-access-form"
          },
          {
            name: "Safety and outings",
            component: "saftey-and-outings-form",
            step: 2.4
          },
          {
            name: "Behaviour",
            component: "behaviour-form",
            step: 2.5
          }
        ]
      },
      {
        name: "Medical",
        step: 3,
        component: "medical-form",
        children: [
          {
            name: "Medical details",
            component: "medical-form",
            step: 3.2
          },
          {
            name: "Medical contacts",
            component: "medical-contacts-form",
            step: 3.4
          },
          {
            name: "Medication",
            component: "medication-form",
            step: 3.3
          }
        ]
      },
      {
        name: "Contacts",
        step: 4,
        component: "contact-list",
        children: []
      },
      {
        name: "Employment, Training and Education",
        component: "employment-training-education-form",
        step: 5,
        children: []
      },
      {
        name: "Banking",
        component: "banking-form",
        step: 6,
        children: []
      },
      {
        name: "Signatures",
        component: "signatures-form",
        step: 7,
        children: []
      }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.parentStep = this.timeline[0];
      this.selectedStep = this.timeline[0].children[0];
      if (this.$route.query.step != undefined) {
        let parentStepNbr = this.$route.query.step;
        let childStepNbr = 0;
        const step = this.$route.query.step;
        if (step.indexOf(".") > -1) {
          parentStepNbr = parseInt(step.substring(0, step.indexOf(".")));
          childStepNbr = step;
        }

        this.parentStep = this.timeline.find(route => {
          return route.step == parentStepNbr;
        });
        if (step.indexOf(".") > -1) {
          this.selectedStep = this.parentStep.children.find(route => {
            return route.step == childStepNbr;
          });
        } else {
          this.selectedStep = this.parentStep;
        }
      }
    },
    setSelectedStep(parent, child) {
      this.parentStep = parent;
      if (child != null) {
        this.selectedStep = child;
      }
    },
    next() {
      if (
        this.parentStep == this.selectedStep &&
        this.parentStep.children.length > 0
      ) {
        this.selectedStep = this.parentStep.children[0];
        return;
      }
      if (this.parentStep == this.selectedStep) {
        this.parentStep = this.timeline[
          this.timeline.indexOf(this.parentStep) + 1
        ];
        if (this.parentStep.children.length > 0) {
          this.selectedStep = this.parentStep.children[0];
        } else {
          this.selectedStep = this.parentStep;
        }
        return;
      }

      //Set to next child of current parent  if exists
      var idx = this.parentStep.children.indexOf(this.selectedStep);
      if (idx >= 0 && idx + 1 < this.parentStep.children.length) {
        this.selectedStep = this.parentStep.children[idx + 1];
      }

      //move on to next parent
      if (idx >= 0 && idx + 1 >= this.parentStep.children.length) {
        this.parentStep = this.timeline[
          this.timeline.indexOf(this.parentStep) + 1
        ];
        if (this.parentStep.children.length > 0) {
          this.selectedStep = this.parentStep.children[0];
        } else {
          this.selectedStep = this.parentStep;
        }
      }
    },
    validate() {},
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.v-card__text {
  padding-top: 0px;
}
.v-timeline-item {
  padding-bottom: 10px;
}
.v-timeline {
  padding-top: 0px;
}
.v-timeline--dense:not(.v-timeline--reverse):before {
  left: 20px;
}
.title {
  margin-bottom: 10px;
}
.activetimeline-item .timeline-card {
  border-right: 4px solid #156a59;
}

.selected {
  color: #156a59;
  font-weight: bold;
}
.timeline-text {
  cursor: pointer;
}
.timeline-text:hover {
  color: #156a59;
}
.participant-form {
  padding-top: 30px;
}

.v-timeline--dense .v-timeline-item__body {
  max-width: initial;
}
.v-timeline-item__divider {
  min-width: 42px !important;
  margin-right: 8px;
}
@media only screen and (max-width: 580px) {
  .v-timeline {
    display: none;
  }
}
</style>

