<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-radio-group
          v-model="vm.hasConsentedToTakePhotos"
          label="Do you consent to any photos taken of you to be used for Marketing purposes?"
          v-validate="'required'"
          data-vv-as="hasConsentedToTakePhotos"
          name="hasConsentedToTakePhotos"
          :error-messages="errors.collect('hasConsentedToTakePhotos')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" md="4" v-if="vm.hasConsentedToTakePhotos">
        <v-text-field
          v-model="vm.personGivingConsentPhotos"
          label="Name of person giving consent"
          v-validate="'required'"
          data-vv-as="Person giving Consent"
          name="personGivingConsentPhotos"
          :error-messages="errors.collect('personGivingConsentPhotos')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.hasConsentedToShareWithGov"
          label="Do you consent to sharing information with Government Bodies?"
          v-validate="'required'"
          data-vv-as="Consent to Share Information with Government"
          name="hasConsentedToShareWithGov"
          :error-messages="errors.collect('hasConsentedToShareWithGov')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" md="4" v-if="vm.hasConsentedToShareWithGov">
        <v-text-field
          v-model="vm.personGivingConsentGov"
          label="Name of person giving consent"
          v-validate="'required'"
          data-vv-as="Person Giving Consent"
          name="personGivingConsentGov"
          :error-messages="errors.collect('personGivingConsentGov')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.hasConsentedoShareWithParents"
          label="Do you consent to sharing information with participant's own parents/carers?"
          v-validate="'required'"
          data-vv-as="Consent to Share Information Parents or Carers"
          name="hasConsentedoShareWithParents"
          :error-messages="errors.collect('hasConsentedoShareWithParents')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" md="4" v-if="vm.hasConsentedoShareWithParents">
        <v-text-field
          v-model="vm.personGivingConsentParents"
          label="Name of person giving consent"
          v-validate="'required'"
          data-vv-as="Person Giving Consent"
          name="personGivingConsentParents"
          :error-messages="errors.collect('personGivingConsentParents')"
        ></v-text-field>
      </v-col>
      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "consent-form",
  model: {},
  data: () => ({
    vm: {},
    form: {}
  }),
  props: {
    msg: String
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.consentDetails == undefined
          ? {}
          : this.$store.getters.currentCase.consentDetails;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.consentDetails = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

