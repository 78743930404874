<template>
  <v-container fluid>
    <div class="infobox caption mb-4">
      <v-icon size="40" color="blue" @click="snackbar = false">mdi-information</v-icon>
      <div class="ml-6">
        <strong>Scale:</strong>
        <div>1. Low = Support rarely needed</div>
        <div>2. Medium = Support sometimes needed</div>
        <div>3. High = Support always needed</div>
      </div>
    </div>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12" style="background-color:#f9f9f9" class="mb-4">
        <v-subheader class="pl-0 mb-4">Self care</v-subheader>
        <v-slider
          v-model="vm.selfCare"
          :tick-labels="lowToHigh"
          color="#156a59"
          :max="3"
          step="1"
          ticks="always"
          thumb-label="always"
          thumb-size="24"
          tick-size="4"
          name="selfCare"
        ></v-slider>
      </v-col>

      <v-col cols="12" style="background-color:#f9f9f9" class="mb-4">
        <v-subheader class="pl-0 mb-4">Toileting</v-subheader>
        <v-slider
          v-model="vm.toileting"
          :tick-labels="lowToHigh"
          color="#156a59"
          :max="3"
          step="1"
          ticks="always"
          thumb-label="always"
          thumb-size="24"
          tick-size="4"
          name="toileting"
        ></v-slider>
      </v-col>

      <v-col cols="12" style="background-color:#f9f9f9" class="mb-4">
        <v-subheader class="pl-0 mb-4">"Eating and drinking</v-subheader>
        <v-slider
          v-model="vm.eatingAndDrinking"
          :tick-labels="lowToHigh"
          color="#156a59"
          :max="3"
          step="1"
          ticks="always"
          thumb-label="always"
          thumb-size="24"
          tick-size="4"
          name="eatingAndDrinking"
        ></v-slider>
      </v-col>
      <v-col cols="12" style="background-color:#f9f9f9" class="mb-4">
        <v-subheader class="pl-0 mb-4">Mobility</v-subheader>
        <v-slider
          v-model="vm.mobility"
          :tick-labels="lowToHigh"
          color="#156a59"
          :max="3"
          step="1"
          ticks="always"
          thumb-label="always"
          thumb-size="24"
          tick-size="4"
          name="mobility"
        ></v-slider>
      </v-col>

      <v-col cols="12" style="background-color:#f9f9f9" class="mb-4">
        <v-subheader class="pl-0 mb-4">Communication (civic) and economic life</v-subheader>
        <v-slider
          v-model="vm.communicationCivicAndEconomicLife"
          :tick-labels="lowToHigh"
          color="#156a59"
          :max="3"
          step="1"
          ticks="always"
          thumb-label="always"
          thumb-size="24"
          tick-size="4"
          name="communicationCivicAndEconomicLife"
        ></v-slider>
      </v-col>
      <v-col cols="12" style="background-color:#f9f9f9" class="mb-4">
        <v-subheader class="pl-0 mb-4">Interpersonal interactions and relationships</v-subheader>
        <v-slider
          v-model="vm.interpersonalInteractionsAndRelationships"
          :tick-labels="lowToHigh"
          color="#156a59"
          :max="3"
          label
          step="1"
          ticks="always"
          thumb-label="always"
          thumb-size="24"
          tick-size="4"
          name="interpersonalInteractionsAndRelationships"
        ></v-slider>
      </v-col>
      <v-col cols="12" style="background-color:#f9f9f9" class="mb-4">
        <v-subheader class="pl-0 mb-4">Learning applying knowledge and general tasks and demands</v-subheader>
        <v-slider
          v-model="vm.learningApplyingKnowledgeAndGeneralTasksDemands"
          :tick-labels="lowToHigh"
          color="#156a59"
          :max="3"
          step="1"
          ticks="always"
          thumb-label="always"
          thumb-size="24"
          tick-size="4"
          name="learningApplyingKnowledgeAndGeneralTasksDemands"
        ></v-slider>
      </v-col>

      <v-col cols="12" style="background-color:#f9f9f9" class="mb-4">
        <v-subheader class="pl-0 mb-4">Education</v-subheader>
        <v-slider
          v-model="vm.education"
          :tick-labels="lowToHigh"
          color="#156a59"
          :max="3"
          step="1"
          ticks="always"
          thumb-label="always"
          thumb-size="24"
          tick-size="4"
          name="education"
        ></v-slider>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.isOnSpecialDiet"
          label="Is the participant on a special diet?"
          v-validate="'required'"
          data-vv-as="Special diet"
          name="isOnSpecialDiet"
          :error-messages="errors.collect('isOnSpecialDiet')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <v-radio-group
          v-model="vm.isHomeAloneAssessed"
          label="Is home alone assessed?"
          v-validate="'required'"
          data-vv-as="home alone assessed"
          name="isHomeAloneAssessed"
          :error-messages="errors.collect('isHomeAloneAssessed')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
var Models = require("./models.js");
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "support-needs",
  data: () => ({
    vm: new Models.SupportNeeds(),
    lowToHigh: ["Please select", "Low", "Medium", "High"],

    form: {}
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.supportNeeds == undefined
          ? {}
          : this.$store.getters.currentCase.supportNeeds;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.supportNeeds = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infobox {
  border: 1px solid hsla(206, 97%, 70%, 0.5);
  display: flex;
}
</style>

