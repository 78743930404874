<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateParentBeforeSubmit">
      <v-col cols="12">
        <v-radio-group
          v-model="vm.doTakeMedication"
          label="Does the participant take medication?"
          v-validate="'required'"
          data-vv-scope="form"
          data-vv-as="Participant medication"
          name="doTakeMedication"
          :error-messages="errors.collect('form.doTakeMedication')"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <div v-if="vm.doTakeMedication==true">
        <v-col cols="12">
          <v-text-field
            v-model="medicationVm.pharmacyName"
            label="Pharmacy Name"
            data-vv-scope="childForm"
            name="pharmacyName"
            :error-messages="errors.collect('childForm.pharmacyName')"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="medicationVm.pharmacyPhone"
            label="Pharmacy Phone Number"
            data-vv-scope="childForm"
            name="pharmacyPhone"
            :error-messages="errors.collect('childForm.pharmacyPhone')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="vm.medicalNotes"
            filled
            rows="1"
            label="Medical notes"
            v-validate="'required'"
            data-vv-scope="form"
            data-vv-as="Medical notes"
            name="medicalNotes"
            :error-messages="errors.collect('form.medicalNotes')"
            auto-grow
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="vm.prefferredPainRelief"
            filled
            rows="1"
            label="Preferred pain relief"
            data-vv-scope="form"
            v-validate="'required'"
            data-vv-as="Prefferred pain relief"
            name="prefferredPainRelief"
            :error-messages="errors.collect('form.prefferredPainRelief')"
            auto-grow
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="vm.allergiesAndReactions"
            filled
            rows="1"
            label="Allergies and reactions"
            v-validate="'required'"
            data-vv-as="Allergies and reactions"
            data-vv-scope="form"
            name="allergiesAndReactions"
            :error-messages="errors.collect('form.allergiesAndReactions')"
            auto-grow
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="vm.treatments"
            filled
            rows="1"
            label="Treatments"
            v-validate="'required'"
            data-vv-scope="form"
            data-vv-as="Treatment"
            name="treatments"
            :error-messages="errors.collect('form.treatments')"
            auto-grow
          ></v-textarea>
        </v-col>
      </div>
      <v-btn @click="dialog=true" v-if="vm.doTakeMedication==true">Add medication</v-btn>
      <v-data-table
        v-if="meds!=undefined && vm.doTakeMedication==true"
        id="list-table"
        ref="datatable"
        :headers="headers"
        :items="meds"
        :loading="loading"
        item-key="index"
      >
        <template slot="item.actions" slot-scope="props">
          <div>
            <v-icon slot="activator" small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>
            <v-icon slot="activator" small @click="deleteItem(props.item)">mdi-delete</v-icon>
          </div>
        </template>
      </v-data-table>
      <v-spacer></v-spacer>
      <div>
        <v-btn color="primary" type="submit">Continue</v-btn>
      </div>
    </v-form>
    <v-dialog v-model="dialog" width="600" v-if="vm.doTakeMedication==true">
      <v-form ref="childform" @submit.prevent="validateBeforeSubmit">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>Medication</v-card-title>
          <v-card-text>
            <v-col cols="12">
              <v-radio-group
                v-model="medicationVm.medicationType"
                label="Medication Type"
                v-validate="'required'"
                data-vv-scope="childForm"
                data-vv-as="Medication type"
                name="medicationType"
                :error-messages="errors.collect('childForm.medicationType')"
              >
                <v-radio label="Short Term PRN" value="Short Term PRN"></v-radio>
                <v-radio label="Regular" value="Regular"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="medicationVm.medicationName"
                label="Medication Name"
                v-validate="'required'"
                data-vv-scope="childForm"
                data-vv-as="Medication name"
                name="medicationName"
                :error-messages="errors.collect('childForm.medicationName')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="medicationVm.dosage"
                label="Dosage"
                v-validate="'required'"
                data-vv-scope="childForm"
                data-vv-as="Dosage"
                name="dosage"
                :error-messages="errors.collect('childForm.dosage')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="medicationVm.method"
                label="Method"
                v-validate="'required'"
                data-vv-scope="childForm"
                data-vv-as="Method"
                name="method"
                :error-messages="errors.collect('childForm.method')"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-menu
                ref="medicationTimeMenu"
                v-model="medicationTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="medicationVm.medicationTime"
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="medicationVm.medicationTime"
                    label="Medication Time"
                    prepend-icon="mdi-clock-outline"
                    v-validate="'required'"
                    data-vv-scope="childForm"
                    data-vv-as="Medication time"
                    name="medicationTime"
                    :error-messages="errors.collect('childForm.medicationTime')"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="medicationTimeMenu"
                   format="24hr"
                   :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55,60]"
                  v-model="medicationVm.medicationTime"
                  full-width
                  @click:minute="$refs.medicationTimeMenu.save(medicationVm.medicationTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="medicationVm.medicalReason"
                label="Medical Reason"
                data-vv-scope="childForm"
                v-validate="'required'"
                data-vv-as="Medical reason"
                name="medicalReason"
                :error-messages="errors.collect('childForm.medicalReason')"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="medicationVm.possibleReactions"
                filled
                rows="1"
                label="Possible Reactions"
                v-validate="'required'"
                data-vv-scope="childForm"
                data-vv-as="Possible reactions"
                name="possibleReactions"
                :error-messages="errors.collect('childForm.possibleReactions')"
                auto-grow
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="medicationVm.procedureMissed"
                filled
                rows="1"
                label="Procedure if Missed"
                v-validate="'required'"
                data-vv-scope="childForm"
                data-vv-as="Procedure missed"
                name="procedureMissed"
                :error-messages="errors.collect('childForm.procedureMissed')"
                auto-grow
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="medicationVm.prescribingDr"
                :items="currentCase.medicalContacts"
                label="Prescribing Dr"
                v-validate="'required'"
                data-vv-as="Prescribing Dr"
                data-vv-scope="childForm"
                name="prescribingDr"
                :error-messages="errors.collect('childForm.prescribingDr')"
              >
                <template
                  v-slot:selection="{ item, parent, selected }"
                >{{ item.firstName }} - {{ item.familyName }}</template>
                <template v-slot:item="{ item}">{{ item.firstName }} - {{ item.familyName }}</template>
              </v-combobox>
            </v-col>

            <v-col cols="12">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="medicationVm.datePrescribed"
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="medicationVm.datePrescribed"
                    label="Date prescribed"
                    v-on="on"
                    readonly
                    v-validate="'required'"
                    data-vv-scope="childForm"
                    data-vv-as="datePrescribed"
                    name="datePrescribed"
                    :error-messages="errors.collect('childForm.datePrescribed')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="medicationVm.datePrescribed"
                  no-title
                  scrollable
                  color="green lighten-1"
                  dark
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false">Cancel</v-btn>
                  <v-btn
                    flat
                    color="primary"
                    @click="$refs.menu1.save(medicationVm.datePrescribed)"
                  >OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="medicationVm.dateStarted"
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="medicationVm.dateStarted"
                    label="Date Started"
                    v-on="on"
                    readonly
                    v-validate="'required'"
                    data-vv-scope="childForm"
                    data-vv-as="dateStarted"
                    name="dateStarted"
                    :error-messages="errors.collect('childForm.dateStarted')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="medicationVm.dateStarted"
                  no-title
                  scrollable
                  color="green lighten-1"
                  dark
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.menu.save(medicationVm.dateStarted)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close">Cancel</v-btn>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>



<script>
var Models = require("./models.js");
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "medication-form",
  data: () => ({
    dialog: false,
    vm: new Models.Medical(),
    meds: [],
    medicationVm: {},
    form: {},
    childForm: {},
    medicationTimeMenu: false,
    menu: false,
    menu1: false,

    totalItems: 0,
    loading: false,
    queryFilters: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "Medication type", value: "medicationType" },
      { text: "Name", value: "medicationName" },
      { text: "", value: "actions" }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.medicationVm = {};
      this.meds =
        this.currentCase.medication === undefined ||
        this.currentCase.medication.items === undefined
          ? []
          : this.currentCase.medication.items;

      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.medication == undefined
          ? { items: [] }
          : this.$store.getters.currentCase.medication;
    },
    editItem(item) {
      this.editedIndex = this.meds.indexOf(item);
      this.medicationVm = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.meds.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.meds.splice(index, 1);
        this.vm.items = this.meds;
        this.currentCase.medication = this.vm;
        this.$store.dispatch("setCurrentCase", this.currentCase);
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.medicationVm = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    },
    validateParentBeforeSubmit() {
      this.$validator.validateAll("form").then(result => {
        if (result) {
          this.currentCase.medication = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    validateBeforeSubmit() {
      this.$validator.validateAll("childForm").then(result => {
        if (result) {
          if (this.editedIndex > -1) {
            Object.assign(this.meds[this.editedIndex], this.medicationVm);
          } else {
            this.meds.push(this.medicationVm);
          }
          this.vm.items = this.meds;
          this.currentCase.medication = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.close();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    next() {
      this.$emit("next-step");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

