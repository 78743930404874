<template>
  <v-container>
    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ on }">
        <v-spacer></v-spacer>
        <v-btn v-on="on">Add contact</v-btn>
      </template>
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>Contact</v-card-title>

          <v-card-text>
            <v-col cols="12" sm="6">
              <v-radio-group
                v-model="vm.contactType"
                label="Contact Type"
                v-validate="'required'"
                name="contactType"
                :error-messages="errors.collect('contactType')"
              >
                <v-radio label="Primary" value="Primary"></v-radio>
                <v-radio label="Secondary" value="Secondary"></v-radio>
                <v-radio label="Other" value="Other"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.firstName"
                label="First name"
                v-validate="'required'"
                data-vv-as="First name"
                name="firstName"
                :error-messages="errors.collect('firstName')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.familyName"
                label="Family name"
                v-validate="'required'"
                data-vv-as="Family name"
                name="familyName"
                :error-messages="errors.collect('familyName')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="vm.dateOfBirth"
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="vm.dateOfBirth"
                    label="Date Of Birth"
                    readonly
                    v-on="on"
                    name="dateOfBirth"
                    :error-messages="errors.collect('dateOfBirth')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="vm.dateOfBirth"
                  no-title
                  scrollable
                  color="green lighten-1"
                  dark
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(vm.dateOfBirth)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.homePhone"
                label="Home phone number"
                name="homePhone"
                :error-messages="errors.collect('homePhone')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.workPhone"
                label="Work phone number"
                name="workPhone"
                :error-messages="errors.collect('workPhone')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.mobilePhone"
                label="Mobile phone number"
                name="mobilePhone"
                :error-messages="errors.collect('mobilePhone')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="vm.email"
                label="E-mail address"
                v-validate="'required|email'"
                data-vv-as="Email address"
                name="email"
                :error-messages="errors.collect('email')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="vm.preferredContactMethod"
                :items="contactMethods"
                label="Preferred contact method"
                v-validate="'required'"
                data-vv-as="preferredContactMethod"
                name="preferredContactMethod"
                :error-messages="errors.collect('preferredContactMethod')"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <address-picker
                :detail-mode="false"
                label="Address"
                :min-characters="4"
                v-model="vm.address"
                :required="true"
                v-validate="'required'"
                data-vv-as="Address"
                name="address"
                :error-messages="errors.collect('address')"
              ></address-picker>
            </v-col>

            <v-col cols="12" sm="6">
              <v-radio-group
                v-model="vm.isPostalTheSame"
                label="Is your Postal Address the same?"
                v-validate="'required'"
                data-vv-as="Is postal the same"
                name="isPostalTheSame"
                :error-messages="errors.collect('isPostalTheSame')"
              >
                <v-radio label="Yes" v-bind:value="true"></v-radio>
                <v-radio label="No" v-bind:value="false"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="6" v-if="!vm.isPostalTheSame">
              <address-picker
                :detail-mode="false"
                label="Address"
                :min-characters="4"
                v-model="vm.postalAddress"
                :required="true"
                v-validate="'required'"
                data-vv-as="Postal Address"
                name="postalAddress"
                :error-messages="errors.collect('postalAddress')"
              ></address-picker>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="vm.relationship"
                :items="relationships"
                label="Relationship"
                v-validate="'required'"
                data-vv-as="Relationship"
                name="relationship"
                :error-messages="errors.collect('relationship')"
              ></v-select>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close">Cancel</v-btn>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-row>
      <v-data-table
        id="list-table"
        ref="datatable"
        :headers="headers"
        :items="contacts"
        :loading="loading"
        :dense="true"
        item-key="index"
      >
        <template slot="item.actions" slot-scope="props">
          <div>
            <v-icon slot="activator" small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>

            <v-icon slot="activator" small @click="deleteItem(props.item)">mdi-delete</v-icon>
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-spacer></v-spacer>
    <v-btn color="primary" @click="next">Continue</v-btn>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import AddressPicker from "../../../components/core/AddressPicker";
Vue.use(VeeValidate);

export default {
  name: "contact-list",
  components: {
    AddressPicker
  },
  data: () => ({
    dialog: false,
    vm: {},
    contacts: [],
    form: {},
    menu: false,
    valid: true,
    totalItems: 0,
    loading: false,
    queryFilters: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "Contact type", value: "contactType" },
      { text: "First name", value: "firstName" },
      { text: "Family name", value: "familyName" },
      { text: "Email", value: "email" },
      { text: "Home phone", value: "homePhone" },
      { text: "Mobile", value: "mobilePhone" },
      { text: "Work phone", value: "workPhone" },
      { text: "", value: "actions" }
    ],
    relationships: [
      "Carer",
      "Legal Guardian",
      "Parent",
      "Sibling",
      "Grandparent",
      "Extended Family",
      "Partner",
      "Accomodation Provider"
    ],
    contactMethods: ["Phone", "SMS", "Email"],

    checkbox: false
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm = {};
      this.contacts =
        this.currentCase.contacts === undefined ||
        !Array.isArray(this.currentCase.contacts)
          ? []
          : this.currentCase.contacts;
    },
    editItem(item) {
      this.editedIndex = this.contacts.indexOf(item);
      this.vm = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.contacts.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        this.contacts.splice(index, 1);
        this.currentCase.contacts = this.contacts;
        this.$store.dispatch("setCurrentCase", this.currentCase);
      }
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.editedIndex > -1) {
            Object.assign(this.contacts[this.editedIndex], this.vm);
          } else {
            this.contacts.push(this.vm);
          }
          this.currentCase.contacts = this.contacts;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.close();
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    },

    next() {
      this.$emit("next-step");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

